<template>
  <div>
    <!-- For Free Text -->
    <div v-html="data.questionFreeText" @copy="copyText()"></div>

    <br />
    <br />

    <!-- For uploading video -->
    <template
      v-if="
        (data.questionVideoPlayed < 2 && data.questionVideoLimit) ||
        !data.questionVideoLimit
      "
    >
      <div v-for="(file, f) in data.questionFile" :key="f">
        <div class="columns">
          <div class="column is-12">
            <!-- For Displaying Video -->
            <video
              width="320"
              height="240"
              controls
              @ended="endedVideo()"
              disablePictureInPicture
              controlsList="nodownload"
              playsinline
            >
              <source :src="getUrlFile(file.url)" />
            </video>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
  methods: {
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file;
    },
    endedVideo() {
      this.data.questionVideoPlayed += 1;

      this.$emit("clicked");
    },
    copyText() {
      const selection = document.getSelection();

      this.$emit("copy-text", selection.toString());
    },
  },
};
</script>

<style lang="scss">
video::-webkit-media-controls-timeline {
  display: none;
}
</style>

<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <template v-if="data.optionRules == 'Regular Button'">
          <span v-if="data.require == true">
            <span v-if="rulesData.checkboxTypeAnswer == 'min'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="
                  `requiredarray|checkboxmin:${rulesData.checkboxMinAnswer}`
                "
                v-slot="{ errors }"
              >
                <div :style="differentiateStyle(optionData)">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                  >
                    <div class="columns">
                      <div class="column is-12">
                        <template v-if="option.type == 'text'">
                          <b-checkbox
                            type="is-hcc"
                            v-model="data.answer"
                            :native-value="option.optionId"
                            @input="checkRulesCheckbox()"
                            @copy="copyText()"
                            :disabled="
                              isDisabledButton &&
                                !data.answer.includes(option.optionId)
                            "
                          >
                            {{ option.title }}
                          </b-checkbox>
                        </template>
                        <template v-else-if="option.type == 'image'">
                          <b-checkbox
                            type="is-hcc"
                            v-model="data.answer"
                            :native-value="option.optionId"
                            @input="checkRulesCheckbox()"
                            :disabled="
                              isDisabledButton &&
                                !data.answer.includes(option.optionId)
                            "
                          >
                            <img
                              :src="getUrlFile(option.fileUrl)"
                              alt="Image"
                              style="width: auto;"
                            />
                          </b-checkbox>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span v-if="rulesData.checkboxTypeAnswer == 'max'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="
                  `requiredarray|checkboxmax:${rulesData.checkboxMaxAnswer}`
                "
                v-slot="{ errors }"
              >
                <div :style="differentiateStyle(optionData)">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                  >
                    <div class="columns">
                      <div class="column is-7">
                        <template v-if="option.type == 'text'">
                          <b-checkbox
                            type="is-hcc"
                            v-model="data.answer"
                            :native-value="option.optionId"
                            @input="checkRulesCheckbox()"
                            @copy="copyText()"
                            :disabled="
                              isDisabledButton &&
                                !data.answer.includes(option.optionId)
                            "
                          >
                            {{ option.title }}
                          </b-checkbox>
                        </template>
                        <template v-else-if="option.type == 'image'">
                          <b-checkbox
                            type="is-hcc"
                            v-model="data.answer"
                            :native-value="option.optionId"
                            @input="checkRulesCheckbox()"
                            :disabled="
                              isDisabledButton &&
                                !data.answer.includes(option.optionId)
                            "
                          >
                            <img
                              :src="getUrlFile(option.fileUrl)"
                              alt="Image"
                              style="width: auto;"
                            />
                          </b-checkbox>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span v-if="rulesData.checkboxTypeAnswer == 'all'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="
                  `requiredarray|requiredarraybetween:${rulesData.checkboxMinAnswer},${rulesData.checkboxMaxAnswer}`
                "
                v-slot="{ errors }"
              >
                <div :style="differentiateStyle(optionData)">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                  >
                    <div class="columns">
                      <div class="column is-7">
                        <template v-if="option.type == 'text'">
                          <b-checkbox
                            type="is-hcc"
                            v-model="data.answer"
                            :native-value="option.optionId"
                            @input="checkRulesCheckbox()"
                            @copy="copyText()"
                            :disabled="
                              isDisabledButton &&
                                !data.answer.includes(option.optionId)
                            "
                          >
                            {{ option.title }}
                          </b-checkbox>
                        </template>
                        <template v-else-if="option.type == 'image'">
                          <b-checkbox
                            type="is-hcc"
                            v-model="data.answer"
                            :native-value="option.optionId"
                            @input="checkRulesCheckbox()"
                            :disabled="
                              isDisabledButton &&
                                !data.answer.includes(option.optionId)
                            "
                          >
                            <img
                              :src="getUrlFile(option.fileUrl)"
                              alt="Image"
                              style="width: auto;"
                            />
                          </b-checkbox>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
          </span>
          <span v-else>
            <span v-if="rulesData.checkboxTypeAnswer == 'min'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="`checkboxmin:${rulesData.checkboxMinAnswer}`"
                v-slot="{ errors }"
              >
                <div
                  v-for="(option, indexOption) in optionData"
                  :key="indexOption"
                >
                  <div class="columns">
                    <div class="column is-7">
                      <template v-if="option.type == 'text'">
                        <b-checkbox
                          type="is-hcc"
                          v-model="data.answer"
                          :native-value="option.optionId"
                          @input="checkRulesCheckbox()"
                          @copy="copyText()"
                          :disabled="
                            isDisabledButton &&
                              !data.answer.includes(option.optionId)
                          "
                        >
                          {{ option.title }}
                        </b-checkbox>
                      </template>
                      <template v-else-if="option.type == 'image'">
                        <b-checkbox
                          type="is-hcc"
                          v-model="data.answer"
                          :native-value="option.optionId"
                          @input="checkRulesCheckbox()"
                          :disabled="
                            isDisabledButton &&
                              !data.answer.includes(option.optionId)
                          "
                        >
                          <img
                            :src="getUrlFile(option.fileUrl)"
                            alt="Image"
                            style="width: auto;"
                          />
                        </b-checkbox>
                      </template>
                    </div>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span v-if="rulesData.checkboxTypeAnswer == 'max'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="`checkboxmax:${rulesData.checkboxMaxAnswer}`"
                v-slot="{ errors }"
              >
                <div
                  v-for="(option, indexOption) in optionData"
                  :key="indexOption"
                >
                  <div class="columns">
                    <div class="column is-7">
                      <template v-if="option.type == 'text'">
                        <b-checkbox
                          type="is-hcc"
                          v-model="data.answer"
                          :native-value="option.optionId"
                          @input="checkRulesCheckbox()"
                          @copy="copyText()"
                          :disabled="
                            isDisabledButton &&
                              !data.answer.includes(option.optionId)
                          "
                        >
                          {{ option.title }}
                        </b-checkbox>
                      </template>
                      <template v-else-if="option.type == 'image'">
                        <b-checkbox
                          type="is-hcc"
                          v-model="data.answer"
                          :native-value="option.optionId"
                          @input="checkRulesCheckbox()"
                          :disabled="
                            isDisabledButton &&
                              !data.answer.includes(option.optionId)
                          "
                        >
                          <img
                            :src="getUrlFile(option.fileUrl)"
                            alt="Image"
                            style="width: auto;"
                          />
                        </b-checkbox>
                      </template>
                    </div>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span v-if="rulesData.checkboxTypeAnswer == 'all'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="
                  `requiredarraybetween:${rulesData.checkboxMinAnswer},${rulesData.checkboxMaxAnswer}`
                "
                v-slot="{ errors }"
              >
                <div
                  v-for="(option, indexOption) in optionData"
                  :key="indexOption"
                >
                  <div class="columns">
                    <div class="column is-7">
                      <template v-if="option.type == 'text'">
                        <b-checkbox
                          type="is-hcc"
                          v-model="data.answer"
                          :native-value="option.optionId"
                          @input="checkRulesCheckbox()"
                          @copy="copyText()"
                          :disabled="
                            isDisabledButton &&
                              !data.answer.includes(option.optionId)
                          "
                        >
                          {{ option.title }}
                        </b-checkbox>
                      </template>
                      <template v-else-if="option.type == 'image'">
                        <b-checkbox
                          type="is-hcc"
                          v-model="data.answer"
                          :native-value="option.optionId"
                          @input="checkRulesCheckbox()"
                          :disabled="
                            isDisabledButton &&
                              !data.answer.includes(option.optionId)
                          "
                        >
                          <img
                            :src="getUrlFile(option.fileUrl)"
                            alt="Image"
                            style="width: auto;"
                          />
                        </b-checkbox>
                      </template>
                    </div>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
          </span>
        </template>
        <template v-else-if="data.optionRules == 'Text Inside Button'">
          <span v-if="data.require == true">
            <span v-if="rulesData.checkboxTypeAnswer == 'min'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="
                  `requiredarray|checkboxmin:${rulesData.checkboxMinAnswer}`
                "
                v-slot="{ errors }"
              >
                <div class="columns image-selector is-multiline">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                    :class="
                      option.type == 'text' ? 'column' : 'column is-narrow'
                    "
                  >
                    <template v-if="option.type == 'text'">
                      <b-checkbox-button
                        type="is-hcc"
                        v-model="data.answer"
                        :native-value="option.optionId"
                        class="my-radio-text"
                        @input="checkRulesCheckbox()"
                        @copy="copyText()"
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                      >
                        {{ option.title }}
                      </b-checkbox-button>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <input
                        :id="option.optionId"
                        type="checkbox"
                        v-model="data.answer"
                        :value="option.optionId"
                        @input="
                          checkRulesCheckbox(option.optionId, indexOption)
                        "
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                        style="margin-right: 10px"
                      />
                      <label
                        class="my-radio-image"
                        :for="option.optionId"
                        style="
                          max-height: 300px;
                          max-width: 300px;
                          object-fit: cover;
                          background-size: auto;
                        "
                        :style="
                          getSizeImage(option.optionId) >= 300
                            ? 'width: 100%;'
                            : 'width: auto;'
                        "
                      >
                        <img
                          :id="`image-${option.optionId}`"
                          :src="getUrlFile(option.fileUrl)"
                          alt=""
                          style="width: auto; max-width: 100%"
                        />
                      </label>
                    </template>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span v-if="rulesData.checkboxTypeAnswer == 'max'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="
                  `requiredarray|checkboxmax:${rulesData.checkboxMaxAnswer}`
                "
                v-slot="{ errors }"
              >
                <div class="columns image-selector is-multiline">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                    :class="
                      option.type == 'text' ? 'column' : 'column is-narrow'
                    "
                  >
                    <template v-if="option.type == 'text'">
                      <b-checkbox-button
                        type="is-hcc"
                        v-model="data.answer"
                        :native-value="option.optionId"
                        @input="checkRulesCheckbox()"
                        @copy="copyText()"
                        class="my-radio-text"
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                      >
                        {{ option.title }}
                      </b-checkbox-button>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <input
                        :id="option.optionId"
                        type="checkbox"
                        v-model="data.answer"
                        :value="option.optionId"
                        @input="
                          checkRulesCheckbox(option.optionId, indexOption)
                        "
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                        style="margin-right: 10px"
                      />
                      <label
                        class="my-radio-image"
                        :for="option.optionId"
                        style="
                          max-height: 300px;
                          max-width: 300px;
                          object-fit: cover;
                          background-size: auto;
                        "
                        :style="
                          getSizeImage(option.optionId) >= 300
                            ? 'width: 100%;'
                            : 'width: auto;'
                        "
                      >
                        <img
                          :id="`image-${option.optionId}`"
                          :src="getUrlFile(option.fileUrl)"
                          alt=""
                          style="width: auto; max-width: 100%"
                        />
                      </label>
                    </template>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span v-if="rulesData.checkboxTypeAnswer == 'all'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="
                  `requiredarray|requiredarraybetween:${rulesData.checkboxMinAnswer},${rulesData.checkboxMaxAnswer}`
                "
                v-slot="{ errors }"
              >
                <div class="columns image-selector is-multiline">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                    :class="
                      option.type == 'text' ? 'column' : 'column is-narrow'
                    "
                  >
                    <template v-if="option.type == 'text'">
                      <b-checkbox-button
                        type="is-hcc"
                        v-model="data.answer"
                        :native-value="option.optionId"
                        @input="checkRulesCheckbox()"
                        @copy="copyText()"
                        class="my-radio-text"
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                      >
                        {{ option.title }}
                      </b-checkbox-button>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <input
                        :id="option.optionId"
                        type="checkbox"
                        v-model="data.answer"
                        :value="option.optionId"
                        @change="
                          checkRulesCheckbox(option.optionId, indexOption)
                        "
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                        style="margin-right: 10px; z-index: 10"
                      />
                      <label
                        class="my-radio-image"
                        :for="option.optionId"
                        style="
                          max-height: 300px;
                          max-width: 300px;
                          object-fit: cover;
                          background-size: auto;
                        "
                        :style="
                          getSizeImage(option.optionId) >= 300
                            ? 'width: 100%;'
                            : 'width: auto;'
                        "
                      >
                        <img
                          :id="`image-${option.optionId}`"
                          :src="getUrlFile(option.fileUrl)"
                          alt=""
                          style="width: auto; max-width: 100%"
                        />
                      </label>
                    </template>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
          </span>
          <span v-else>
            <span v-if="rulesData.checkboxTypeAnswer == 'min'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="`checkboxmin:${rulesData.checkboxMinAnswer}`"
                v-slot="{ errors }"
              >
                <div class="columns image-selector is-multiline">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                    :class="
                      option.type == 'text' ? 'column' : 'column is-narrow'
                    "
                  >
                    <template v-if="option.type == 'text'">
                      <b-checkbox-button
                        type="is-hcc"
                        v-model="data.answer"
                        :native-value="option.optionId"
                        class="my-radio-text"
                        @input="checkRulesCheckbox()"
                        @copy="copyText()"
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                      >
                        {{ option.title }}
                      </b-checkbox-button>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <input
                        :id="option.optionId"
                        type="checkbox"
                        v-model="data.answer"
                        :value="option.optionId"
                        @input="
                          checkRulesCheckbox(option.optionId, indexOption)
                        "
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                        style="margin-right: 10px"
                      />
                      <label
                        class="my-radio-image"
                        :for="option.optionId"
                        style="
                          max-height: 300px;
                          max-width: 300px;
                          object-fit: cover;
                          background-size: auto;
                        "
                        :style="
                          getSizeImage(option.optionId) >= 300
                            ? 'width: 100%;'
                            : 'width: auto;'
                        "
                      >
                        <img
                          :id="`image-${option.optionId}`"
                          :src="getUrlFile(option.fileUrl)"
                          alt=""
                          style="width: auto; max-width: 100%"
                        />
                      </label>
                    </template>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span v-if="rulesData.checkboxTypeAnswer == 'max'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="`checkboxmax:${rulesData.checkboxMaxAnswer}`"
                v-slot="{ errors }"
              >
                <div class="columns image-selector is-multiline">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                    :class="
                      option.type == 'text' ? 'column' : 'column is-narrow'
                    "
                  >
                    <template v-if="option.type == 'text'">
                      <b-checkbox-button
                        type="is-hcc"
                        v-model="data.answer"
                        :native-value="option.optionId"
                        class="my-radio-text"
                        @input="checkRulesCheckbox()"
                        @copy="copyText()"
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                      >
                        {{ option.title }}
                      </b-checkbox-button>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <input
                        :id="option.optionId"
                        type="checkbox"
                        v-model="data.answer"
                        :value="option.optionId"
                        @input="
                          checkRulesCheckbox(option.optionId, indexOption)
                        "
                        :disabled="
                          isDisabledButton &&
                            !data.answer.includes(option.optionId)
                        "
                        style="margin-right: 10px"
                      />
                      <label
                        class="my-radio-image"
                        :for="option.optionId"
                        style="
                          max-height: 300px;
                          max-width: 300px;
                          object-fit: cover;
                          background-size: auto;
                        "
                        :style="
                          getSizeImage(option.optionId) >= 300
                            ? 'width: 100%;'
                            : 'width: auto;'
                        "
                      >
                        <img
                          :id="`image-${option.optionId}`"
                          :src="getUrlFile(option.fileUrl)"
                          alt=""
                          style="width: auto; max-width: 100%"
                        />
                      </label>
                    </template>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
            <span v-if="rulesData.checkboxTypeAnswer == 'all'">
              <ValidationProvider
                :vid="`checkboxtype${indexSection}${indexAssessment}`"
                name="checkbox"
                :rules="
                  `requiredarraybetween:${rulesData.checkboxMinAnswer},${rulesData.checkboxMaxAnswer}`
                "
                v-slot="{ errors }"
              >
                <div class="columns image-selector is-multiline">
                  <div
                    v-for="(option, indexOption) in optionData"
                    :key="indexOption"
                    :class="
                      option.type == 'text' ? 'column' : 'column is-narrow'
                    "
                  >
                    <template v-if="option.type == 'text'">
                      <b-radio-button
                        type="is-hcc"
                        size="is-medium"
                        outlined
                        expanded
                        v-model="data.answer"
                        :native-value="option.optionId"
                        @copy="copyText()"
                        @input="checkRulesRadioButton()"
                      >
                        {{ option.title }}
                      </b-radio-button>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <input
                        :id="option.optionId"
                        type="radio"
                        v-model="data.answer"
                        :value="option.optionId"
                        @input="checkRulesRadioButton(option.optionId)"
                      />
                      <label
                        class="my-radio-image"
                        :for="option.optionId"
                        style="
                          max-height: 300px;
                          max-width: 300px;
                          object-fit: cover;
                          background-size: auto;
                        "
                        :style="
                          getSizeImage(option.optionId) >= 300
                            ? 'width: 100%;'
                            : 'width: auto;'
                        "
                      >
                        <img
                          :id="`image-${option.optionId}`"
                          :src="getUrlFile(option.fileUrl)"
                          alt=""
                          style="width: auto; max-width: 100%"
                        />
                      </label>
                    </template>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </span>
          </span>
        </template>
      </div>

      <!-- For True Answer -->
      <template
        v-if="
          type == 'introductionTest' &&
            data.answer &&
            rulesData.multipleChoiceType == 'Pass/Fail'
        "
      >
        <div v-if="isAnswerTrue">
          <div v-html="data.textTrueAnswer" @copy="copyText()"></div>
        </div>
        <!-- For false answer -->
        <div
          v-else-if="
            !isAnswerTrue &&
              data.answer.length >= rulesData.checkboxMaxAnswer &&
              rulesData.checkboxTypeAnswer[0] == true &&
              rulesData.checkboxTypeAnswer[1] == false
          "
        >
          <div v-html="data.textFalseAnswer" @copy="copyText()"></div>
        </div>
        <div
          v-else-if="
            !isAnswerTrue &&
              data.answer.length >= rulesData.checkboxMinAnswer &&
              rulesData.checkboxTypeAnswer[0] == false &&
              rulesData.checkboxTypeAnswer[1] == true
          "
        >
          <div v-html="data.textFalseAnswer" @copy="copyText()"></div>
        </div>
        <div
          v-else-if="
            (!isAnswerTrue &&
              data.answer.length >= rulesData.checkboxMaxAnswer) ||
              (data.answer.length >= rulesData.checkboxMaxAnswer &&
                rulesData.checkboxTypeAnswer[0] == true &&
                rulesData.checkboxTypeAnswer[1] == true)
          "
        >
          <div v-html="data.textFalseAnswer" @copy="copyText()"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    rulesData: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexAssessment: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
  data() {
    return {
      checkboxSelected: [],
      optionData: [],
      isDisabledButton: false,
      isAnswerTrue: false,
    }
  },
  mounted() {
    if (this.data.answer == '' || this.data.answer == null) {
      this.data.answer = []
    }
    if (this.rulesData.randomType != null) {
      if (this.rulesData.randomType[2] == true) {
        this.randomOptionArray()
        this.checkRulesCheckbox()
      } else {
        this.optionData = this.data.option
      }
    } else {
      this.optionData = this.data.option
    }
  },
  watch: {
    'data.answer': function() {
      this.submit()
    },
  },
  methods: {
    submit() {
      var today = new Date()

      var date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate()

      var time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()

      var dateTime = date + ' ' + time
      this.data.time = dateTime
      this.$emit('clicked')
    },
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },
    getSizeImage(id) {
      let image = document.getElementById('image-' + id)

      if (image != null) {
        image = image.naturalWidth
      }

      return image
    },
    checkRulesCheckbox() {
      if (this.rulesData.checkboxTypeAnswer[1] == true) {
        if (this.data.answer.length >= this.rulesData.checkboxMaxAnswer) {
          this.isDisabledButton = true
        } else {
          this.isDisabledButton = false
        }
      } else {
        this.isDisabledButton = false
      }
      if (this.data.answerOption.length != 0) {
        if (
          JSON.stringify(this.sortedArray(this.data.answerOption)) ==
          JSON.stringify(this.sortedArray(this.data.answer))
        ) {
          this.isAnswerTrue = true
        } else {
          this.isAnswerTrue = false
        }
      } else {
        this.answerTrue = false
      }
    },
    sortedArray(arr) {
      return arr.slice().sort(function(a, b) {
        return a - b
      })
    },
    randomOptionArray() {
      let list = JSON.parse(JSON.stringify(this.data.option))
      list = list.sort(() => Math.random() - 0.5)

      this.optionData = list
    },
    differentiateStyle(arr) {
      let type = arr[0].type
      if (type == 'image') {
        return 'display:flex;justify-content:space-between'
      } else {
        return ''
      }
    },
    copyText() {
      const selection = document.getSelection()

      this.$emit('copy-text', selection.toString())
    },
  },
}
</script>

<style scoped>
.columns div.column input {
  margin-top: -6rem;
}

.image-selector div input {
  margin: 0;
  padding: 0;
  margin: 5px 0 0 12px;
}

.image-selector div input:active + .my-radio-image {
  opacity: 0.9;
}
.image-selector div input:checked + .my-radio-image {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
  border: 5px solid #00a3a3;
  height: auto;
}
.my-radio-image {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100px;
  height: 70px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  -webkit-filter: grayscale(1) opacity(0.7);
  -moz-filter: grayscale(1) opacity(0.7);
  filter: grayscale(1) opacity(0.7);
}
/* @media screen and (min-width: 1216px) {
  .my-radio-image {
    height: 250px !important;
  }
}
@media screen and (min-width: 1408px) {
  .my-radio-image {
    height: 350px !important;
  }
} */
.my-radio-image:hover {
  -webkit-filter: grayscale(0.5) opacity(0.9);
  -moz-filter: grayscale(0.5) opacity(0.9);
  filter: grayscale(0.5) opacity(0.9);
  border: 5px solid #00a3a3;
  height: auto;
}

.my-radio-text {
  height: auto;
  white-space: normal;
}

/* Extras */
a:visited {
  color: #888;
}
a {
  color: #444;
  text-decoration: none;
}
p {
  margin-bottom: 0.3em;
}

.image-selector div label {
  margin-left: 7px;
}
span.cc {
  color: #6d84b4;
}
</style>

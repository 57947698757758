var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[(_vm.data.optionRules == 'Regular Button')?[_c('ValidationProvider',{attrs:{"vid":("radiobuttontype" + _vm.indexSection + _vm.indexAssessment),"name":"Radio Button","rules":("" + (_vm.data.require ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.optionData))},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[(option.type == 'text')?[_c('b-radio',{attrs:{"type":"is-hcc","native-value":option.optionId},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton(option.optionId)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('b-radio',{attrs:{"type":"is-hcc","native-value":option.optionId},on:{"input":function($event){return _vm.checkRulesRadioButton(option.optionId)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_c('img',{staticStyle:{"width":"auto"},attrs:{"src":_vm.getUrlFile(option.fileUrl),"alt":"Image"}})])]:_vm._e()],2)])])}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4065427522)})]:(_vm.data.optionRules == 'Text Inside Button')?[_c('ValidationProvider',{attrs:{"vid":("radiobuttontype" + _vm.indexSection + _vm.indexAssessment),"name":"Radio Button","rules":("" + (_vm.data.require ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"columns is-multiline image-selector"},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.optionId},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton(option.optionId)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.answer),expression:"data.answer"}],attrs:{"id":option.optionId,"type":"radio"},domProps:{"value":option.optionId,"checked":_vm._q(_vm.data.answer,option.optionId)},on:{"input":function($event){return _vm.checkRulesRadioButton(option.optionId)},"change":function($event){return _vm.$set(_vm.data, "answer", option.optionId)}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"300px","max-width":"300px","background-size":"auto","object-fit":"cover"},style:(_vm.getSizeImage(option.optionId) >= 300
                      ? 'width: 100%;'
                      : 'width: auto;'),attrs:{"for":option.optionId}},[_c('img',{staticStyle:{"width":"auto","max-width":"100%"},attrs:{"id":("image-" + (option.optionId)),"src":_vm.getUrlFile(option.fileUrl),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})]:_vm._e()],2),(
        _vm.type == 'introductionTest' &&
          _vm.rulesData.multipleChoiceType == 'Pass/Fail' &&
          this.data.answerOption[0] != null
      )?[(_vm.isAnswerTrue)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.textTrueAnswer)},on:{"copy":function($event){return _vm.copyText()}}})]):(!_vm.isAnswerTrue && _vm.data.answer != null)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.textFalseAnswer)},on:{"copy":function($event){return _vm.copyText()}}})]):_vm._e()]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }